<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>服务</h3>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <ul
        class="blocks-xs-2 blocks-md-2 blocks-lg-4 blocks-xxl-4 met-pager-ajax imagesize cover met-product-list met-grid"
        id="met-grid"
        data-scale="400x400"
        style="position: relative; height: 382.25px"
      >
        <li class="shown" style="position: absolute; left: 0px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=9"
                title="咨询服务"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1545445267.jpg"
                  alt="咨询服务"
                  data-lazyload="true"
                  src="/images/ser1.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=9"
                title="咨询服务"
                class="block"
                target="_self"
                >咨询服务</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 334px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=8"
                title="系统集成"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1545391604.jpg"
                  alt="系统集成"
                  data-lazyload="true"
                  src="/images/ser2.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=8"
                title="系统集成"
                class="block"
                target="_self"
                >系统集成</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 668px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=10"
                title="售后服务"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1545445353.jpg"
                  alt="售后服务"
                  data-lazyload="true"
                  src="/images/ser3.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=10"
                title="售后服务"
                class="block"
                target="_self"
                >售后服务</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
#met-grid {
  li {
    padding: 2%;
    margin: 1%;
    img {
      width: 300px;
      height: 300px;
    }
  }
}
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>